import { graphql, useStaticQuery } from "gatsby"

const useProjects = () => {
  const data = useStaticQuery(graphql`
    query {
      allProjects(
        sort: { fields: rank, order: ASC }
        filter: { publish: { eq: true }, building_type: { eq: "branded" } }
      ) {
        nodes {
          ...offplanCardFragment
        }
      }
    }
  `)

  const projects = data?.allProjects?.nodes

  return projects
}

export default useProjects
